const content =
{
    currentlanguage: "Türkçe",
    name: "İsim",
    currency: "Para Birimi",
    value: "Değer",
    buy: "Alış",
    sell: "Satış",
    dailyChange: "Günlük Değişim",
    test1: [
        "", ""
    ],
    test2: " option selected",
    test3: {
        test4: {
            test5: ""
        },
        test6: {
            test7: ""
        },
        test8: {
            test9: "",
            test10: ``
        }
    },
    test11: [
        {
            test12: "",
            test13: ``,
            test14: [
                {
                    test15: "",
                    test16: ``,
                    value: "AV:N"
                }
            ]
        }
    ],
    test17: ""

};

export default content;