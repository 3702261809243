import { useState, useEffect, useCallback } from 'react';

const useFetchAll = () => {
    const [statusDoviz, setStatusDoviz] = useState({loadingDoviz: true });
    const [dataDoviz, setDataDoviz] = useState([]);

    const [statusManisaKuyum, setStatusManisaKuyum] = useState({loadingManisaKuyum: true });
    const [dataManisaKuyum, setDataManisaKuyum] = useState([]);

    const [statusSeninBankan, setStatusSeninBankan] = useState({loadingSeninBankan: true });
    const [dataSeninBankan, setDataSeninBankan] = useState([]);

    //setStatusDoviz({ loadingDoviz: true });
    //setStatusManisaKuyum({ loadingManisaKuyum: true });
    //setStatusSeninBankan({ loadingSeninBankan: true });

    const [state, setState] = useState(false);

    const loadDataOnlyOnce = useCallback(() => {
      console.log(`Reloaded Data! - ${state}!!`);
      setState(true)
    }, [state]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetch(`https://borsa.ramazansancar.com.tr/api`).then((response) => response.json())
                    .then((datares) => {
                        const {datas} = datares;
                        const {
                            doviz, 
                            manisaKuyum, 
                            seninBankan
                        } = datas;

                        //console.log('datas')
                        if(doviz){
                            setDataDoviz(doviz);
                            setStatusDoviz({ loadingDoviz: false });
                            //console.log('doviz')
                        }
                        
                        if(manisaKuyum){
                            setDataManisaKuyum(manisaKuyum);
                            setStatusManisaKuyum({ loadingManisaKuyum: false });
                            //console.log('manisaKuyum')
                        }

                        if(seninBankan){
                            setDataSeninBankan(seninBankan);
                            setStatusSeninBankan({ loadingSeninBankan: false });
                            //console.log('seninBankan')
                        }
                });
            }catch(error){
                console.log(error);
            }
        }
        fetchData();
        setState(false);
    }, [state]);

    return {
        statusDoviz, dataDoviz,
        statusManisaKuyum, dataManisaKuyum,
        loadDataOnlyOnce, 
        statusSeninBankan, dataSeninBankan
    };
};

export default useFetchAll;