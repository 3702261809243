/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect/*, useMemo*/ } from 'react';
import './Home.css';
import { useTranslation } from 'react-i18next';
import useFetchAll from './components/useFetchAll.js';
//import useFetchDoviz from './components/useFetchDoviz.js';
//import useFetchManisaKuyum from './components/useFetchManisaKuyum.js';
//import useFetchSeninBankan from './components/useFetchSeninBankan.js';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link as MuiLink } from "@mui/material";
import { localeOptions, locale } from './locale/config.js';
//import { use } from 'i18next';


function App(){
    const [language, setLanguage] = useState(locale);
    //const [pageSize, setPageSize] = useState(10);

    const {
        statusDoviz: { loadingDoviz }, dataDoviz,
        statusManisaKuyum: { loadingManisaKuyum }, dataManisaKuyum,
        statusSeninBankan: { loadingSeninBankan }, dataSeninBankan
    } = useFetchAll();

    const dovizRows = dataDoviz
    const manisaKuyumRows = dataManisaKuyum
    const seninBankanRows = dataSeninBankan

    useEffect(() => {
        //console.log('Language Changed: ' + language);
    }, [language]);

    const { loadDataOnlyOnce } = useFetchAll();

    const { t, i18n } = useTranslation();
    
    const manisaKuyumColumns = [
        { field: 'id', headerName: '#', width: 75 },
        { field: 'name', headerName: t("name"), width: 140 },
        { field: 'buy', headerName: t("buy"), width: 85, sortable: false },
        { field: 'sell', headerName: t("sell"), width: 85, sortable: false },
    ];
    
    const dovizColumns = [
        { field: 'id', headerName: '#', width: 75 },
        { field: 'name', headerName: t("name"), width: 110 },
        { field: 'value', headerName: t("value"), width: 90, sortable:false }
    ];

    const seninBankanColumns = [
        { field: 'id', headerName: '#', width: 75 },
        { field: 'currencyCode', headerName: t("name"), width: 110 },
        { field: 'sell', headerName: t("sell"), width: 110, sortable:true },
        { field: 'buy', headerName: t("buy"), width: 100, sortable:true  },
        { field: 'change', headerName: t("dailyChange"), width: 170, sortable:true  },
        { field: 'name', headerName: t("name"), width: 250, sortable:true },
    ];

    /*const seninBankanColumns = [
        { field: 'Fec', headerName: '#', width: 75 },
        { field: 'CurrencyShortCode', headerName: t("name"), width: 100 },
        { field: 'CurrencyDescription', headerName: t("name"), width: 250, sortable:true },
        { field: 'CurrencyBid', headerName: t("sell"), width: 100, sortable:true },
        { field: 'CurrencyAsk', headerName: t("buy"), width: 100, sortable:true  },
        { field: 'DailyChange', headerName: 'Daily Change', width: 100, sortable:true  },
    ];*/

    return (
    <div className="App">
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="#">Borsa Takip</a>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span></button>
        </header>

        <div className="container-fluid">
        <div className="row">
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-3 sidebar-sticky">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#">
                            <span className="align-text-bottom bi bi-life-preserver"></span>
                            API
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="#manisakuyum">
                            <span className="align-text-bottom bi bi-life-preserver"></span>
                            Manisa Kuyum
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="#doviz">
                            <span className="align-text-bottom bi bi-life-preserver"></span>
                            Döviz
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="#seninbankan">
                            <span className="align-text-bottom bi bi-life-preserver"></span>
                            Kuveyt Türk
                        </a>
                    </li>
                </ul>
                <span style={{display:'flex', flexDirection:'column', margin: '0px 0px 0px 0px'}}>Select Language: <b>{t("currentlanguage")}</b></span>
                <MuiLink
                    className="language--link"
                    onClick={() => i18n.changeLanguage("en") && window.localStorage.setItem('currentLanguage', 'en') && setLanguage('en')}
                    underline="hover">
                    <img src={localeOptions.filter(a => a.id==='en')[0].icon} alt="US Flag" height="32px" width="32px" />
                </MuiLink>
                <span className="language--seperator">&nbsp;|&nbsp;</span>
                <MuiLink
                    className="language--link"
                    onClick={() => i18n.changeLanguage("tr") && window.localStorage.setItem('currentLanguage', 'tr') && setLanguage('tr')}
                    underline="hover">
                    <img src={localeOptions.filter(a => a.id==='tr')[0].icon} alt="TR Flag" height="32px" width="32px" />
                </MuiLink>
                    <Button className="m-2" onClick={loadDataOnlyOnce} variant="outlined">Update Me!</Button>
            </div>
            </nav>

            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <div className="container text-center">
                        <h1 className="h2"><i className="bi bi-bank"></i> API</h1>
                        <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                            <div className="col">
                                <div className="p-3 border bg-light"><a className="active" href="/api/">/api/</a></div>
                            </div>
                            <div className="col">
                                <div className="p-3 border bg-light"><a className="active" href="/api/status/">/api/status/</a></div>
                            </div>
                            <div className="col">
                                <div className="p-3 border bg-light"><a className="active" href="/api/doviz/">/api/doviz/</a></div>
                            </div>
                            <div className="col">
                                <div className="p-3 border bg-light"><a className="active" href="/api/manisakuyum/">/api/manisakuyum/</a></div>
                            </div>
                            <div className="col">
                                <div className="p-3 border bg-light"><a className="active" href="/api/seninbankan/">/api/seninbankan/</a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 id="manisakuyum" className="mt-2"><i className="bi bi-stack"></i> Manisa Kuyumcu </h2>
                <p><u>Son Güncellenme: {manisaKuyumRows.lastupdate}</u></p>
                {!loadingManisaKuyum ? (
                    <Box style={{display: 'flex'}} sx={{ height: 650, width: '100%'  }}>
                        <DataGrid
                            rows={manisaKuyumRows.data}
                            columns={manisaKuyumColumns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Box>
                ) : (
                    <Box sx={{ width: '150px', padding: "150px", margin: "auto" }}>
                        <CircularProgress />
                        {(loadingManisaKuyum) ? 'Loading...' : (manisaKuyumRows.status !== 200) ? manisaKuyumRows.message : ''}
                    </Box>
                )}
                
                <h2 id="doviz" className="mt-2"><i className="bi bi-stack"></i> Döviz (tlkur.com)</h2>
                {!loadingDoviz ? (
                    <Box style={{display: 'flex'}} sx={{ height: 1210, width: '100%'  }}>
                        <DataGrid
                            rows={dovizRows.data}
                            columns={dovizColumns}
                            pageSize={21}
                            rowsPerPageOptions={[21]}
                        />
                    </Box>
                ) : (
                    <Box sx={{ width: '150px', padding: "150px", margin: "auto" }}>
                        <CircularProgress />
                        {(loadingDoviz) ? 'Loading...' : (dovizRows.status !== 200) ? dovizRows.message : ''}
                    </Box>
                )}
                
                <h2 id="seninbankan" className="mt-2"><i className="bi bi-stack"></i> Kuveyt Türk </h2>
                {!loadingSeninBankan && seninBankanRows.data ? (
                    <Box style={{display: 'flex'}} sx={{ height: 1540, width: '100%'  }}>
                        <DataGrid
                            rows={seninBankanRows.data}
                            columns={seninBankanColumns}
                            getRowId={(row) => row?.id}
                            pageSize={35}
                        />
                    </Box>
                ) : (
                    <Box sx={{ width: '150px', padding: "150px", margin: "auto" }}>
                        <CircularProgress />
                        {(loadingSeninBankan) ? 'Loading...' : (seninBankanRows.status !== 200) ? seninBankanRows.message : ''}
                    </Box>
                )}

            </main>
        </div>
        </div>
    </div>
    );
}

export default App;
